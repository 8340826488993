.questionare-page {
  margin-top: 5%;
}

.btn-questionare {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 10px;
  color: #ffffff;
  background-color: #2596be;
  text-align: center;
  cursor: pointer;
  width: 30%;
  font-size: 18px;
}

.questionare-section {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border: 0 !important;
}

.btn-questionare:hover, a:hover {
  opacity: 0.7;
}
