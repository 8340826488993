.kyc-page {
  margin-top: 3%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 1000px;
  margin: auto;
}

.btn {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 10px;
  color: #ffffff;
  background-color: #2596be;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.btn:hover, a:hover {
  opacity: 0.7;
}

h2 {
  text-align: center;
}

p {
  text-align: center;
  font-weight: bold;
}


.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 15%;
  text-align: center;
  margin-top: 4%;
}

.title {
  color: grey;
  font-size: 18px;
  text-align: center;
}

a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

.error {
  color: #800000;
}

/* Full-width inputs */
.form-control {
 width: 100%;
 padding: 12px 20px;
 margin: 8px 0;
 display: inline-block;
 border: 1px solid #ccc;
 box-sizing: border-box;
}

/* Add padding to containers */
.container {
 padding: 16px;
 width: 300px;
 margin-left: auto;
 margin-right: auto;
 display: block;
}

/* The "Forgot password" text */
span.psw {
 float: right;
 padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
 span.psw {
   display: block;
   float: none;
 }
 .cancelbtn {
   width: 100%;
 }
}
