.home-page {
  margin-top: 5%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 1000px;
  margin: auto;
}

.btn {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 10px;
  color: #ffffff;
  background-color: #2596be;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.btn:hover, a:hover {
  opacity: 0.7;
}

h2 {
  text-align: center;
}

p {
  text-align: center;
  font-weight: bold;
}


.logo {
  /* margin-top: 5%; */
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 18%;
  text-align: center;
}

.title {
  color: grey;
  font-size: 18px;
  text-align: center;
}

a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}
